#message {
    padding: 50px 50px;
    margin-top: 8rem;
    background-color: #eeecf0;
  }
  
  .message-container {
    display: flex;
    justify-content: center;
  }

  .image-content {
    background-color: #dcccd8;
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    color: var(--color-primary);
    padding-right: 0;
  }

  .image-content > * {
    margin: 0;
  }

  .ceo-image{
    position: relative;
    right: 0;
    width: 15rem;
    padding: 0;
  }

  .message-heading {
    background-color: var(--color-primary);
    padding: 10px;
    height: 4rem;
    align-items: center;
    color: #dcccd8;
    font-size: x-large;
  }

  .message-heading h6 {
    margin: 0.1rem ;
  }
  
  .message-content {
    background-color: #dcccd8;
    height: 150px;
    width: 300px;
    align-items: center;
    padding: 1rem;
    margin-left: 2rem;
    margin-top: auto;
    color: var(--color-primary);
  }

  .para-starting {
    font-weight: bold;
  }

  @media screen and (max-width: 1200px){
    #message {
      margin-top: 8rem;
    }
    .message-container {
      display: grid;
      grid-template-columns: repeat(1,1fr);
    }
    .image-content,.message-heading,.message-content  {
      margin: 0 auto;
    }
    .message-content {
      margin-top: 1rem;
    }
  }

