/* Navbar section start */

#navbar {
    position: relative;
    top: 40px;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 0;
    margin: 0 auto;
    /* width: 100%; */
  }

  
  .nav-links {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    height: 77px;
    padding-right: 20px; 
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.2);
  }

  .nav-logo {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.2);
  }
  
  .navlogo {
    height: 77px;
    width: 16rem;
  }
  
  .nav-link-container {
    display: flex;
    gap: 1.5rem;
    list-style: none;
  }
  
  
  .nav-items-container {
    display: flex;
  }
  
  
  .nav-link-items {
    color: var(--color-primary);
    font-weight: bold;
  }
  
   .nav-link-items:hover {
    text-decoration: underline;
    text-decoration-color: var(--color-primary);
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
    cursor: pointer;
    animation: underlineAppear 0.5s ease forwards; 
  }

  @keyframes underlineAppear {
    from {
      text-decoration: underline transparent; 
    }
    to {
      text-decoration-color: var(--color-primary); 
    }
  }
  
  .social-links {
    display: flex;
    height: 100%;
  
  }
  
  .social-link-container {
    display: flex;
    gap: 1.5rem;
    list-style: none;
  }
  
  .social-link-items {
    color: var(--color-primary);
    opacity: 1;
    transition: opacity 1s ease 0s;
  }

  .social-link-items:hover {
    opacity: 0.7;
  }
  
  
  
  @media screen and (max-width: 1200px) {
    #navbar{
      justify-content:space-around;
      width: 100%;
    }
  
    .nav-logo {
      z-index: 2;
    }
    .hamburger-menu {
      position: absolute;
      top: 25px;
      right: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 20px;
      height: 20px;
      z-index: 2;
    }
    .nav-line {
      display: block;
      background-color: var(--color-primary);
      width: 100%;
      height: 0.188rem;
      border-radius: 0.625rem;
      transition: all ease-in-out 0.2s;
    }
    .nav-links {
      background: var(--main-bg-color);
      background: linear-gradient(162deg, var(--bg-color-first-shade) 0%, var(--bg-color-second-shade) 88%);
      position: absolute;
      width: 100%;
      align-items: center;
      left: 0;
      top: -20rem;
      z-index: 1;
      transition: all ease-in-out 0.4s;
      box-sizing: border-box;
      padding-right: 0;
    }

    .social-links {
      display: none;
    }
  
    .nav-links ul {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 3rem 0 0.6rem;
      z-index: -100;
    }
    .nav-links ul li {
      text-align: center;
    }
  
    .nav-items-container {
      flex-direction: column;
    }
    .nav-link-container {
      flex-direction: column;
    }
  
   }
  
   .nav-links.nav-active {
    top: 30px;
    height: auto;
   }
  
   .hamburger-menu.nav-active :nth-child(1){
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
   }
   .hamburger-menu.nav-active :nth-child(2){
    opacity: 0;
   }
   .hamburger-menu.nav-active :nth-child(3){
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
   }
  
  /* Navbar section end */