
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
  background-color: rgba(255,247,252,1);

}

:root {
  --main-bg-color: rgba(195,157,178,255);
  --bg-color-first-shade : rgba(195,157,178,255);
  --bg-color-second-shade : rgba(255,247,252,1);
  --color-primary : #5c1c44;
  --color-white : #ffffff;

}

