#news {
    padding-top: 2rem;
    background-color: var(--color-primary);
}

.news-container-title {
    text-align: center;
    color: var(--color-white);
    font-size: 3rem;
    font-weight: 400;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
}

.btn-news {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    font-weight: bold;
    font-size: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

.btn-news:hover {
    transform: scale(1.5);
}

.news-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    position: relative;
    top: 40px;
}

.news-card-container.no-news {
    display: block;
}

.news-card {
    margin: 0 2rem;
    background-color: var(--color-white);
    -webkit-box-shadow: 20px 20px 84px -11px rgba(0,0,0,0.1);
    -moz-box-shadow: 20px 20px 84px -11px rgba(0,0,0,0.1);
    box-shadow: 20px 20px 84px -11px rgba(0,0,0,0.1);
}

.news-card-img {
    height: auto; 
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-img {
    max-height: 100%; 
    max-width: 100%; 
    height: auto; 
    width: auto; 
}
.news-card-content {
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.news-card-content-title {
    text-align: center;
    color: var(--color-primary);
    font-weight: bold;
    margin-bottom: 0;
}

.news-card-content-para {
    text-align: left;
    color: #222831;
    font-weight: normal;
    font-size: small;
    padding: 0 1rem;
}

.no-news-message {
    text-align: center;
    font-weight: 500;
    color: var(--color-primary);
}

.news-card-link {
    cursor: cell;
}

@media screen and (max-width: 1200px){

    .news-card-container {
        display: flex;
        flex-wrap: wrap;      
    }
    .news-card-img {
        height: 14rem; 
        width: 100%; 
        align-items: flex-start;
    }

    .news-card {
        margin: 0 auto;       
    }

    .btn-news {
        width: 1.7rem;
        height: 1.7rem;
        padding: 0;
    }
    
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
    .news-card-img {
        height: 27rem; 
    }
}