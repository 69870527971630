#about {
    background: var(--main-bg-color);
    background: linear-gradient(90deg, var(--bg-color-first-shade) 0%, var(--bg-color-second-shade) 33%, var(--bg-color-second-shade) 69%, var(--bg-color-first-shade) 100%);
}

.about-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 50px;
    gap: 3rem;
}

.about-card {
    padding: 20px 30px;
    margin: 40px auto;
    width: 300px;
    height: 350px;
    align-items: center;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.about-card:hover {
    background-color: var(--color-white);
    transform: translateY(-60px) translateX(5px);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.2);
}

.about-icon {
    height: 90px;
    width: 90px;
}


.about-content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 50px;
    gap: 3rem;
    background-color: var(--color-white);
    margin: 0.5rem 2rem ;
}

.about-content {
    color: var(--color-primary);
}

.about-content p {
    margin-bottom: 20px;
    line-height: 1.3; 
}

.about-top-heading {
    font-weight: bold;
    font-size: xx-large;
    text-align: center;
    padding-top: 2rem;
    margin: 0 0 2rem 0;
}

.about-card > h3 {
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 1200px){
    .about-card-container {
        display: flex;
        flex-direction: column;
    }

    .about-card {
        width: 200px;
    }

    .about-card-container {
        gap: 0.2rem;
    }

    .about-content-container {
        display: flex;
        flex-direction: column-reverse;
        padding: 20px 30px;
        margin: 40px 10px;
    }
  }

@media screen and (min-width: 500px) and (max-width: 1200px) {
    .about-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

}