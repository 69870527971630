#contact {
    background: var(--main-bg-color);
    background: linear-gradient(90deg, var(--bg-color-first-shade) 0%, var(--bg-color-second-shade) 33%, var(--bg-color-second-shade) 69%, var(--bg-color-first-shade) 100%);
    padding: 5rem 0;
}

.contact-container {
    background-color: var(--color-white);
    padding: 50px 50px;
    gap: 3rem;
    margin: 1rem 2rem ;
    align-items: center;
    text-align: center;
    color: var(--color-primary);
}

.contact-form-img-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact--section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
  padding-top: 50px;
  padding-left: 50px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  flex: 1 0 0;
  width: 100%;
  font-size: 14px;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 4px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--color-primary);
  background: var(--color-white);
  font-size: 14px;
  margin-bottom: -10px;
}

.contact-image {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 100%; 
}
.contact-img {
    height: 250px;
    width: 400px;
}

/* contact start */

  .text-md {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  textarea {
    resize: none;
  }


  @media screen and (max-width: 1200px){
    #contact {
      padding: 1rem 0;
      padding-top: 5rem;
  }
  .contact-img {
      height: 150px;
      width: 220px;
      padding-bottom: 15px;
  }

  .contact-form {
    margin: 0 auto;
  }

  .contact--section{
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .contact-form-img-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .contact--form--container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(5, auto);
    width: 40%;
    row-gap: 32px;
    padding-top: 2rem;
    padding-left: 0;
  }

}


  /* Contact End */





