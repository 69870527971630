#partners{
    background-color: var(--color-white);
    padding: 20px 50px;
}

.partners-icons {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px; 
}

.partners-title {
    text-align: center;
    color: var(--color-primary);
}

.partnesrs-icon-container {
    height: auto; 
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.partners-icon {
    max-height: 100%; 
    max-width: 100%; 
    height: auto; 
    width: auto; 
}

@media screen and (max-width: 1200px){
    .partners-icons {
        display: flex;
        flex-direction: column;
    }
    .partnesrs-icon-container {
        width: 100%; 
        margin-bottom: 20px; 
    }
  
    .partners-icon {
        max-width: 100%; 
        height: auto; 
        width: 8rem; 
    }
  }

@media screen and (min-width: 800px) and (max-width: 1200px) {
    .partners-icons {
        display: grid   ;
        grid-template-columns: repeat(4, 1fr);
    }

    .partnesrs-icon-container {
        width: 100%; 
        margin-bottom: 20px; 
    }
  
    .partners-icon {
        max-width: 100%; 
        height: auto; 
        width: 10rem; 
    }
}


