/* Intoduction section start */

#introduction{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    gap: 3rem;
    padding: 0 30px;
    margin: 100px 0;
  }
  
  .intro-content{
    align-items: center;
    padding: 20px 20px 0;
  }
  
  .intro-heading {
    color: var(--color-primary);
    line-height: 10px;
  }
  
  .intro-heading-1, .intro-heading-2, .intro-heading-3 {
    opacity: 0;
    font-size: 40px;
    animation: slideIn 1s forwards;
  }
  
  .intro-heading-1 {
    font-weight: lighter;
  }
  
  .intro-heading-2 {
    font-weight: bold;
    margin-left: 1.5em;
  }
  
  .intro-heading-3 {
    font-weight: lighter;
    margin-left: 3em;
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .intro-para {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 180px;
    color: #333333;
  }
  
  .btn {
    font-family: "Roboto";
    display: inline-block;
    padding: 14px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: background-color 0.5s;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
  }
  
  .btn-primary {
    color: var(--color-white);
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    font-weight: bold;
    margin: 10px 0 0 20px;
  }
  .btn-primary:hover {
    color: var(--color-primary);
    background-color: var(--color-white);
  }
  
  /*card section */
  
  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding-right: 50px;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .card {
    background-size: cover;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 150px;
    width: 150px;
    position: relative;
    opacity: 0;
    animation: fadeIn 1s ease forwards; 
    transition: transform 0.5s ease, box-shadow 0.5s ease;
  }

  .card:hover {
    transform: translateY(-60px) translateX(5px);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.2);
  }

.card:nth-child(1) {
  animation-delay: 0.5s; 
}

.card:nth-child(2) {
  animation-delay: 1s; 
}

@keyframes fadeIn {
  from {
      opacity: 0; 
  }
  to {
      opacity: 1; 
  }
}

  .card:not(:first-child) {
    margin-top: 20px;
  }
  
  .card .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: rgba(128, 128, 128, 0.5); 
    display: flex;
    align-items: center;
  }
  
  .card .icon {
    width: 140%; 
    height: 140%; 
    position: relative;
    margin-left: -10%; 
    margin-top: -10%; 
  }
  
  .card .title {
    color: var(--color-white);
    margin-right: 20px;
    flex-grow: 1; 
  }
  
  
  .column-2 {
    margin-bottom: 3em;
  }
  
  .column-3 {
    margin-bottom: 6em;
  }
  
  
  /*card section end */
  
  @media screen and (max-width: 1200px){
    #introduction{
        display: flex;
        flex-direction: column;
    }

    .intro-para {
      padding: 10px;
    }

    .card-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-right: 0;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 500px) and (max-width: 1200px) {
    .card-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Introduction section end */